/* eslint-disable */

const $win = $(window);

/**
 *  Function that initialize Location map
 */
function initMap(elem, data) {
	const map = new google.maps.Map(elem, {
		center: data.map.center,
		zoom: data.map.zoom,
	});

	const marker = new google.maps.Marker({
		position: {
			lat: data.marker.position.lat,
			lng: data.marker.position.lng,
		},
		map: map,
	});
}

$win.on("load", () => {
	/**
	 * Initialize  Maps
	 */
	$(".map-banner").each((i, elem) => {
		const data = JSON.parse(elem.dataset.map.replace(/\'/g, '"'));

		initMap(elem, data);
	});
});
