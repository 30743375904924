import "jquery";
import "bootstrap";

import "./lib/map";
import Router from "./util/Router";
import common from "./routes/common";
import galleryViewer from "./routes/galleryViewer";
import home from "./routes/home";

const routes = new Router({
	common,
	galleryViewer,
	home,
});

jQuery(document).ready(() => routes.loadEvents());
