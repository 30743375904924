import Video from '../lib/video';

class Home {
  constructor() {
    Object.assign(this, Object.assign(Object.seal({
      video: new Video(),
    })));
  }
}

export default {
  init() {
    const home = new Home();
    home.video.addListeners();
  },
  finalize() {},
}
